import { FeedClient } from '../../services/ApiService';
import { Post } from '../../types/types';

export function initIndexPage(feedClient: FeedClient) {
  return {
    posts: [] as Post[],
    loading: false,
    currentPage: 1,
    hasMore: true,
    itemsPerPage: 2,
    lastScrollPosition: 0,
    scrollDirection: 'down',
    touchStartY: 0,

    async initIndexPage() {
      // Load initial posts
      await this.loadPosts();
    },

    async loadPosts() {
      if (this.loading || !this.hasMore) return;

      this.loading = true;

      // Get feed posts
      try {
        const feed = await feedClient.getFeed();
        if (feed) {

          const resPosts = this.alternatePosts(feed.posts)

          // Add new posts to the list
          this.posts = [...this.posts, ...resPosts];

          // Update pagination
          this.hasMore = feed.hasMore;
          this.currentPage++;
        }
      } catch (error) {
        console.error('Failed to fetch feed:', error);
      } finally {
        this.loading = false;
      }
    },

    handleScroll(e: Event) {
      const container = e.target as HTMLElement;
      const newScrollPosition = container.scrollTop;

      // Determine scroll direction
      this.scrollDirection = newScrollPosition > this.lastScrollPosition ? 'down' : 'up';
      this.lastScrollPosition = newScrollPosition;

      // Load more posts when near the bottom
      const scrollBottom = container.scrollHeight - container.scrollTop - container.clientHeight;
      if (scrollBottom < 500 && !this.loading && this.hasMore) {
        this.loadPosts();
      }
    },

    togglePlay(video: HTMLVideoElement) {
      if (video.paused) {
        video.muted = true; // Ensure muted for autoplay
        video.play().then(() => {
          // Optionally unmute after successful play
          // video.muted = false;
        }).catch(error => {
          console.error('Error playing video:', error);
        });
      } else {
        video.pause();
      }
    },

    // Touch handling for mobile devices
    handleTouchStart(e: TouchEvent) {
      this.touchStartY = e.touches[0].clientY;
    },

    handleTouchMove(e: TouchEvent) {
      if (!this.touchStartY) return;

      const currentY = e.touches[0].clientY;
      const deltaY = this.touchStartY - currentY;

      // Determine direction and find target snap point
      const direction = deltaY > 0 ? 1 : -1;
      const container = this.$refs.feedContainer;
      const viewportHeight = container.clientHeight;
      const currentIndex = Math.round(container.scrollTop / viewportHeight);
      const targetIndex = currentIndex + direction;
      const targetScroll = targetIndex * viewportHeight;

      // Apply scroll with smooth behavior
      container.scrollTo({
        top: targetScroll,
        behavior: 'smooth'
      });

      this.touchStartY = 0;
    },

    alternatePosts(posts: Post[]): Post[] {
      if (!posts.length) return [];

      // Group posts by creator_id
      const postsByCreator = posts.reduce((acc, post) => {
        if (!acc[post.creator_id]) {
          acc[post.creator_id] = [];
        }
        acc[post.creator_id].push(post);
        return acc;
      }, {} as Record<string, Post[]>);

      // Sort creators by number of posts (descending)
      const creatorQueues = Object.entries(postsByCreator)
        .map(([creator_id, posts]) => ({
          creator_id,
          posts: [...posts],
          count: posts.length
        }))
        .sort((a, b) => b.count - a.count);

      const result: Post[] = [];
      let lastUsedCreatorIndex = -1;

      while (creatorQueues.some(q => q.posts.length > 0)) {
        // Find next available creator that's different from the last used
        let nextCreatorIndex = -1;

        // First try to find a different creator
        for (let i = 0; i < creatorQueues.length; i++) {
          if (i !== lastUsedCreatorIndex && creatorQueues[i].posts.length > 0) {
            nextCreatorIndex = i;
            break;
          }
        }

        // If no different creator found, use the same creator if they have remaining posts
        if (nextCreatorIndex === -1) {
          nextCreatorIndex = creatorQueues.findIndex(q => q.posts.length > 0);
        }

        if (nextCreatorIndex !== -1) {
          const nextPost = creatorQueues[nextCreatorIndex].posts.shift()
          if (nextPost) {
            result.push(nextPost);
          }
          lastUsedCreatorIndex = nextCreatorIndex;
        }

        // Re-sort creators by remaining posts count
        creatorQueues.sort((a, b) => b.posts.length - a.posts.length);
      }

      return result;
    },

  };
}
