import Alpine from 'alpinejs';
import intersect from '@alpinejs/intersect';
import '../components/UploadForm/UploadForm';
import { initIndexPage } from '../components/IndexPage/IndexPage';
import { getCommonAlpineData } from '../utils/common';
import { getMyClipsData } from '../alpine-data/MyClipsPageData';
import { FeedClient } from '../services/ApiService';
import { API_BASE_URL } from '../constants';

// Initialize Alpine plugins
Alpine.plugin(intersect);
(window as typeof window & { Alpine: typeof Alpine }).Alpine = Alpine;

document.addEventListener('alpine:init', () => {
  getCommonAlpineData();

  const feedClient = new FeedClient({
    baseUrl: API_BASE_URL,
    // Optional custom domain for cookies
    //cookieDomain: '.tryonhaulcentral.com',
    // Optional custom error handler
    onError: (error) => {
      console.error('Feed client error:', error);
    }
  });

  // Register main app component
  Alpine.data('app', function() {
    return {
      ...initIndexPage(feedClient),

      // Initialize
      async init() {
        this.initIndexPage()
      },

    };
  });

  getMyClipsData();

});

Alpine.start();

