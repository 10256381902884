// Types for API responses and requests
interface Post {
  title: string;
  id: string;
  thumbnail_file_path: string;
  description: string;
  landscape_video_file_path: string;
  portrait_video_file_path: string;
  tags: string[];
  creator_id: string;
  created_at: string;
  source: {
    Type: 'youtube' | 'x-twitter' | 'original' | 'instagram';
    URL: string;
  };
  duration_seconds: number;
}

interface SearchResult {
  posts: Post[];
  hasMore: boolean;
  nextPage: number;
}

interface ViewRequest {
  post_id: string;
  duration: number;
}

interface FeedClientConfig {
  baseUrl: string;
  cookieDomain?: string;
  onError?: (error: Error) => void;
}

class FeedClient {
  private baseUrl: string;
  private cookieDomain: string;
  private onError: (error: Error) => void;

  constructor(config: FeedClientConfig) {
    this.baseUrl = config.baseUrl.replace(/\/$/, ''); // Remove trailing slash if present
    this.cookieDomain = config.cookieDomain || window.location.hostname;
    this.onError = config.onError || console.error;
  }

  /**
   * Get feed posts for the current user
   */
  async getFeed(): Promise<SearchResult> {
    try {
      const response = await fetch(`${this.baseUrl}/feed`, {
        method: 'GET',
        credentials: 'include', // Include cookies in request
        headers: {
          'Accept': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Check for and store identity cookie if present
      this.handleIdentityCookie(response);

      return await response.json();
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  /**
   * Record a view for a post
   */
  async recordView(viewData: ViewRequest): Promise<void> {
    try {
      const response = await fetch(`${this.baseUrl}/view`, {
        method: 'POST',
        credentials: 'include', // Include cookies in request
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(viewData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Check for and store identity cookie if present
      this.handleIdentityCookie(response);
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  /**
   * Handle storing the identity cookie from response headers
   */
  private handleIdentityCookie(response: Response): void {
    const cookies = response.headers.get('set-cookie');
    if (cookies) {
      cookies.split(',').forEach(cookie => {
        if (cookie.includes('identity_key')) {
          document.cookie = this.formatCookie(cookie);
        }
      });
    }
  }

  /**
   * Format cookie string with domain and security settings
   */
  private formatCookie(cookie: string): string {
    // Remove any existing domain
    let formatted = cookie.replace(/Domain=[^;]+;/, '');
    
    // Add our domain
    formatted += `; Domain=${this.cookieDomain}`;
    
    // Ensure cookie is secure and SameSite=Strict
    if (!formatted.includes('Secure')) {
      formatted += '; Secure';
    }
    if (!formatted.includes('SameSite')) {
      formatted += '; SameSite=Strict';
    }
    
    return formatted;
  }

  /**
   * Handle errors consistently
   */
  private handleError(error: unknown): void {
    const errorObject = error instanceof Error ? error : new Error(String(error));
    this.onError(errorObject);
  }

  /**
   * Get the current identity key from cookies
   */
  getIdentityKey(): string | null {
    const match = document.cookie.match(/identity_key=([^;]+)/);
    return match ? match[1] : null;
  }

  /**
   * Check if client has an identity key
   */
  hasIdentityKey(): boolean {
    return this.getIdentityKey() !== null;
  }
}

export type { Post, SearchResult, ViewRequest, FeedClientConfig };
export { FeedClient };
