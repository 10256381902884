import { Post } from '../types/types';
import { PostsService } from './PostService';

export class ClipCompilationService {
  private readonly STORAGE_KEY = 'thc_clips';

  // Load saved clips from localStorage
  async loadClips(): Promise<Post[] | null> {
    try {
      const saved = localStorage.getItem(this.STORAGE_KEY);
      const clipIds = saved ? JSON.parse(saved) : [];
      if (clipIds.length > 0) {
        return (new PostsService).getPostsById(clipIds)
      }
    } catch (e) {
      console.error('Error loading clips:', e);
      return [];
    }

    return []
  }

  // Save clips to localStorage
  saveClips(clips: string[]) {
    try {
      localStorage.setItem(this.STORAGE_KEY, JSON.stringify(clips));
    } catch (e) {
      console.error('Error saving clips:', e);
    }
  }

  // Add a clip
  addClip(postId: string, currentClips: string[]): string[] {
    if (!this.isClipped(postId, currentClips)) {
      const updatedClips = [...currentClips, postId];
      this.saveClips(updatedClips);
      return updatedClips;
    }
    return currentClips;
  }

  // Remove a clip
  removeClip(post: string, currentClips: string[]): string[] {
    const updatedClips = currentClips.filter(p => p !== post);
    this.saveClips(updatedClips);
    return updatedClips;
  }

  // Check if a post is clipped
  isClipped(postId: string, currentClips: string[]): boolean {
    return currentClips.some(p => p === postId);
  }

}
