import { API_BASE_URL, AVATAR_CDN_BASE_URL } from '../constants';
import { Creator, Post } from '../types/types';

interface Cache<T> {
  value: T;
  timestamp: number;
}

export class CreatorsService {
  private static instance: CreatorsService;
  private creatorCache: Map<string, Cache<Creator>> = new Map();
  private creatorsByTagCache: Map<string, Cache<Creator[]>> = new Map();
  private similarCreatorsCache: Map<string, Cache<Creator[]>> = new Map();
  
  // Promise caches to handle in-flight requests
  private creatorPromiseCache: Map<string, Promise<Creator | null>> = new Map();
  private creatorsByTagPromiseCache: Map<string, Promise<Creator[]>> = new Map();
  private similarCreatorsPromiseCache: Map<string, Promise<Creator[]>> = new Map();
  
  private readonly CACHE_TTL = 60 * 60 * 1000; // 1 hour in milliseconds

  private constructor() {}

  static getInstance(): CreatorsService {
    if (!CreatorsService.instance) {
      CreatorsService.instance = new CreatorsService();
    }
    return CreatorsService.instance;
  }

  private isValidCache<T>(cache: Cache<T> | undefined): boolean {
    if (!cache) return false;
    return Date.now() - cache.timestamp < this.CACHE_TTL;
  }

  private async fetchCreatorById(id: string): Promise<Creator | null> {
    try {
      const response = await fetch(`${API_BASE_URL}/creators/${id}`);
      if (!response.ok) {
        if (response.status === 404) {
          return null;
        }
        throw new Error('Failed to fetch creator');
      }
      const creator = await response.json();
      
      // Update cache
      this.creatorCache.set(id, {
        value: creator,
        timestamp: Date.now()
      });
      
      return creator;
    } catch (error) {
      console.error('Error fetching creator:', error);
      return null;
    } finally {
      // Clear the promise cache after request completes
      this.creatorPromiseCache.delete(id);
    }
  }

  async getCreatorById(id: string): Promise<Creator | null> {
    // Check memory cache first
    const cached = this.creatorCache.get(id);
    if (cached && this.isValidCache(cached)) {
      return cached.value;
    }

    // Check if there's already an in-flight request
    let promise = this.creatorPromiseCache.get(id);
    if (!promise) {
      promise = this.fetchCreatorById(id);
      this.creatorPromiseCache.set(id, promise);
    }

    return promise;
  }

  private async fetchCreatorsByTag(tag: string): Promise<Creator[]> {
    try {
      const params = new URLSearchParams({ tag });
      const response = await fetch(`${API_BASE_URL}/creators?${params}`);
      if (!response.ok) {
        throw new Error('Failed to fetch creators by tag');
      }
      const creators = await response.json();
      
      // Update cache
      this.creatorsByTagCache.set(tag, {
        value: creators,
        timestamp: Date.now()
      });
      
      return creators;
    } catch (error) {
      console.error('Error fetching creators by tag:', error);
      return [];
    } finally {
      // Clear the promise cache after request completes
      this.creatorsByTagPromiseCache.delete(tag);
    }
  }

  async getCreatorsByTag(tag: string): Promise<Creator[]> {
    // Check memory cache first
    const cached = this.creatorsByTagCache.get(tag);
    if (cached && this.isValidCache(cached)) {
      return cached.value;
    }

    // Check if there's already an in-flight request
    let promise = this.creatorsByTagPromiseCache.get(tag);
    if (!promise) {
      promise = this.fetchCreatorsByTag(tag);
      this.creatorsByTagPromiseCache.set(tag, promise);
    }

    return promise;
  }

  async getCreatorAvatar(creatorId: string): Promise<string> {
    const creator = await this.getCreatorById(creatorId);
    if (creator) {
      return AVATAR_CDN_BASE_URL + creator.avatar_url;
    }
    // TODO: default avatar
    return '';
  }

  async searchCreators(query: string, page: number = 1): Promise<{ creators: Creator[], hasMore: boolean }> {
    try {
      const params = new URLSearchParams({
        q: query,
        page: page.toString()
      });
      const response = await fetch(`${API_BASE_URL}/creators?${params}`);
      if (!response.ok) {
        throw new Error('Failed to search creators');
      }
      return await response.json();
    } catch (error) {
      console.error('Error searching creators:', error);
      return {
        creators: [],
        hasMore: false
      };
    }
  }

  private async fetchSimilarCreators(creatorId: string): Promise<Creator[]> {
    try {
      const response = await fetch(`${API_BASE_URL}/creators/${creatorId}/similar`);
      if (!response.ok) {
        throw new Error('Failed to fetch similar creators');
      }
      const creators = await response.json();
      
      // Update cache
      this.similarCreatorsCache.set(creatorId, {
        value: creators,
        timestamp: Date.now()
      });
      
      return creators;
    } catch (error) {
      console.error('Error fetching similar creators:', error);
      return [];
    } finally {
      // Clear the promise cache after request completes
      this.similarCreatorsPromiseCache.delete(creatorId);
    }
  }

  async getSimilarCreators(creatorId: string): Promise<Creator[]> {
    // Check memory cache first
    const cached = this.similarCreatorsCache.get(creatorId);
    if (cached && this.isValidCache(cached)) {
      return cached.value;
    }

    // Check if there's already an in-flight request
    let promise = this.similarCreatorsPromiseCache.get(creatorId);
    if (!promise) {
      promise = this.fetchSimilarCreators(creatorId);
      this.similarCreatorsPromiseCache.set(creatorId, promise);
    }

    return promise;
  }

  async getSimilarCreatorsPosts(creatorIds: string[]): Promise<Post[]> {
    try {
      const filters = {
        creators: creatorIds
      };
      const params = new URLSearchParams({
        filters: JSON.stringify(filters),
        pageSize: '10'  // Limit to 10 posts from similar creators
      });
      const response = await fetch(`${API_BASE_URL}/posts?${params}`);
      if (!response.ok) {
        throw new Error('Failed to fetch similar creators posts');
      }
      const data = await response.json();
      return data.posts;
    } catch (error) {
      console.error('Error fetching similar creators posts:', error);
      return [];
    }
  }

  clearCaches(): void {
    // Clear both value and promise caches
    this.creatorCache.clear();
    this.creatorsByTagCache.clear();
    this.similarCreatorsCache.clear();
    this.creatorPromiseCache.clear();
    this.creatorsByTagPromiseCache.clear();
    this.similarCreatorsPromiseCache.clear();
  }
}
