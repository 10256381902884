import Alpine from 'alpinejs';
import { PostsService } from '../services/PostService';
import { CreatorsService } from '../services/CreatorService';
import { API_BASE_URL, AVATAR_CDN_BASE_URL, CDN_BASE_URL, THUMBNAIL_CDN_BASE_URL } from '../constants';
import { FeedClient } from '../services/ApiService';

export const getCommonAlpineData = () => Alpine.data('common', function() {
  const postsService = new PostsService();
  const creatorsService = CreatorsService.getInstance();

  const feedClient = new FeedClient({
    baseUrl: API_BASE_URL,
    // Optional custom domain for cookies
    //cookieDomain: '.tryonhaulcentral.com',
    // Optional custom error handler
    onError: (error) => {
      console.error('Feed client error:', error);
    }
  });

  return {
    CDN_BASE_URL,
    THUMBNAIL_CDN_BASE_URL,
    AVATAR_CDN_BASE_URL,

    // Services
    postsService,
    creatorsService,

    // State
    darkMode: localStorage.getItem('darkMode') === 'true' || true,
    autoScroll: localStorage.getItem('autoScroll') === 'true' || false,
    wasAutoScroll: false,
    scrollSpeed: localStorage.getItem('scrollSpeed') || 'normal',
    settingsPanelOpen: false,
    scrollInterval: null,

    currentVideoId: null as string | null,

    isLoadingCreatorPosts: false,

    viewedPostIds: [] as string[],

    // Settings handlers
    toggleDarkMode() {
      this.darkMode = !this.darkMode;
      localStorage.setItem('darkMode', String(this.darkMode));
    },

    toggleAutoScroll() {
      this.autoScroll = !this.autoScroll;
      localStorage.setItem('autoScroll', String(this.autoScroll));
      this.syncAutoScroll();
    },

    updateScrollSpeed() {
      localStorage.setItem('scrollSpeed', this.scrollSpeed);
      this.syncAutoScroll();
    },

    syncAutoScroll() {
      if (this.scrollInterval) {
        clearInterval(this.scrollInterval);
      }

      if (this.autoScroll) {
        const speeds = {
          slow: 1,
          normal: 2,
          fast: 4
        };

        this.scrollInterval = setInterval(() => {
          window.scrollBy({
            top: speeds[this.scrollSpeed],
            behavior: 'smooth'
          });
        }, 50);
      }
    },

    dedupeTags(tags: string[]): string[] {
      return Array.from(new Set(tags));
    },

    loadVideo(video: HTMLVideoElement) {
      let needsLoad = false

      const poster = video.dataset.poster
      if (poster && poster !== video.poster) {
        video.setAttribute('poster', poster)
      }

      for (let i = 0; i < video.children.length; i++) {
        const child = video.children.item(i)
        if (child) {
          const src = child.getAttribute('data-src')
          if (src) {
            if (child.getAttribute('src') !== src) {
              child.setAttribute('src', src)
              needsLoad = true
            }
          }
        }
      }

      if (needsLoad) {
        video.load()
      }
    },

    // TODO: fix that this doesn't record the view if they land on a video and dont scroll
    async handleVideoIntersect(video: HTMLVideoElement, isIntersecting: boolean) {
      // record the previous view
      if (this.currentVideoId) {
        const prevVideoEl = document.getElementById('video-' + this.currentVideoId) as HTMLVideoElement | null
        if (prevVideoEl) {
          const time = prevVideoEl.currentTime
          if (time > 1) {
            feedClient.recordView({
              post_id: this.currentVideoId,
              duration: time,
            })
          }
        }
      }
      const videoId = video.id.replace('video-', '');

      if (isIntersecting) {
        // load the video
        this.loadVideo(video)
        // load next video
        const indexRaw = video.getAttribute('data-video-index')
        if (indexRaw) {
          const index = parseInt(indexRaw)
          const nextVideo = document.querySelector('video[data-video-index="' + (index + 1).toString() + '"]')
          if (nextVideo) {
            this.loadVideo(nextVideo as HTMLVideoElement)
          }
        }

        this.currentVideoId = videoId;

        if (window.umami) {
          // TODO: limit this to once per post per ip address per day/interval
          const post_id = video.getAttribute('data-post-id')
          const creator_id = video.getAttribute('data-creator-id')

          if (post_id && creator_id) {
            const seen = this.viewedPostIds.includes(post_id)
            this.viewedPostIds.push(post_id)

            if (!seen) {
              umami.track('video_view', {
                post_id,
                creator_id,
              })
            }
          }
        }

        // Ensure video is muted first for autoplay to work
        video.muted = true;

        try {
          // Reset video position
          video.currentTime = 0;

          // Try to play immediately
          await video.play();

          // Optionally unmute after successful autoplay (if desired)
          // video.muted = false;
        } catch (error) {
          console.warn('Error autoplaying video:', error);

          // Fallback approach - try playing muted
          try {
            video.muted = true;
            await video.play();
          } catch (retryError) {
            console.error('Failed to autoplay even with fallback:', retryError);
          }
        }
      } else {
        if (this.currentVideoId === videoId) {
          this.currentVideoId = null;
        }
        video.pause();
        video.currentTime = 0;
      }
    },

    unescapeHTML(str: string) {
      const textarea = document.createElement('textarea');
      textarea.innerHTML = str;
      return textarea.value;
    },

    secondsToISO8601(durationSeconds: number) {
      // Convert nanoseconds to milliseconds
      const milliseconds = durationSeconds / 1e6;

      // Convert to total seconds
      const totalSeconds = Math.floor(milliseconds / 1000);

      // Calculate hours, minutes, and seconds
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;

      // Build the ISO 8601 string
      let result = "PT";
      if (hours > 0) result += `${hours}H`;
      if (minutes > 0) result += `${minutes}M`;
      if (seconds > 0 || result === "PT") result += `${seconds}S`; // Include seconds if empty

      return result;
    },

    enableSmoothScroll() {
      const container = this.$refs.feedContainer;
      if (!container) return;

      container.addEventListener('wheel', (e: WheelEvent) => {
        e.preventDefault();

        const delta = e.deltaY;
        const currentScroll = container.scrollTop;
        const direction = delta > 0 ? 1 : -1;

        const viewportHeight = container.clientHeight;
        const currentIndex = Math.round(currentScroll / viewportHeight);
        const targetIndex = currentIndex + direction;
        const targetScroll = targetIndex * viewportHeight;

        container.scrollTo({
          top: targetScroll,
          behavior: 'smooth'
        });
      }, { passive: false });
    },


  }
})

