import { ClipCompilationService } from "../../services/ClipCompilationService";
import { Post } from "../../types/types";

export function initClipsComponent() {
  const clipService = new ClipCompilationService();

  return {
    clips: [] as string[],
    clipPosts: [] as Post[],
    showClipSuccessOverlay: false,
    showExportModal: false,
    isExporting: false,

    async initClips() {
      const posts = await clipService.loadClips();
      if (posts) {
        this.clipPosts = posts
      }
    },

    async addToClips(postId: string) {
      // Add to clips
      this.clips = clipService.addClip(postId, this.clips);

      // Show success overlay temporarily
      this.showClipSuccessOverlay = true;
      setTimeout(() => {
        this.showClipSuccessOverlay = false;
      }, 2000);

      // Show clipped state in UI
      const clipButton = document.querySelector(`#clip-${postId}`);
      if (clipButton) {
        clipButton.classList.add('text-primary');
      }

      if (window.umami) {
        if (postId) {
          umami.track('add_to_clips', {
            post_id: postId,
          })
        }
      }
    },

    removeFromClips(postId: string) {
      this.clips = clipService.removeClip(postId, this.clips);

      // Update UI
      const clipButton = document.querySelector(`#clip-${postId}`);
      if (clipButton) {
        clipButton.classList.remove('text-primary');
      }

      if (window.umami) {
        if (postId) {
          umami.track('remove_from_clips', {
            post_id: postId,
          })
        }
      }
    },

    isClipped(post: string): boolean {
      return clipService.isClipped(post, this.clips);
    },

  };
}
